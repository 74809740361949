import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone"
import HistoryTwoTone from "@mui/icons-material/HistoryTwoTone"
import PeopleAltTwoTone from "@mui/icons-material/PeopleAltTwoTone"
import QrCodeTwoTone from "@mui/icons-material/QrCodeTwoTone"
import SendIcon from "@mui/icons-material/Send"
import SettingsTwoTone from "@mui/icons-material/SettingsTwoTone"
import TuneIcon from "@mui/icons-material/Tune"
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Modal,
} from "@mui/material"
import { MemberRole } from "@prisma/client"
import axios from "axios"
import {
  useBusinessQuery,
  useViewerUserQuery,
} from "interface/__generated__/react"
import { useRouter } from "next/router"
import { useEffect, useMemo } from "react"
import type { FC } from "react"
import { AddButton } from "app/components/AddButton"
import { HomeButton } from "app/components/HomeButton"
import { NavigationListItem } from "app/components/NavigationListItem"
import OrangeCapsule from "app/components/OrangeCapsule"
import { SideBarDrawer } from "app/components/SideBarDrawer"

type Props = {
  role: MemberRole
  businessLogin: string
  isOpenDrawer: boolean
  isFullPage?: boolean
  onCloseDrawer(): void
}

export const BusinessDrawer: FC<Props> = (props) => {
  const { data, error: businessError } = useBusinessQuery({
    variables: {
      login: props.businessLogin,
    },
  })

  if (businessError) {
    throw businessError
  }
  const business = data?.business

  const { data: user, error: viewerUserError } = useViewerUserQuery()

  if (viewerUserError) {
    throw viewerUserError
  }

  const stripeCustomerId = user?.viewer?.user?.stripeCustomerId ?? null
  const hasPaymentMethod = user?.viewer?.user?.hasPaymentMethod ?? false

  const router = useRouter()

  const onRouteFactory = (url: string) => {
    return () => {
      router.push(url)
      props.onCloseDrawer()
    }
  }

  useEffect(() => {
    if (business?.subscription.expired && props.role !== MemberRole.OWNER) {
      router.replace(`/${props.businessLogin}/expired`)
    }
  }, [business?.subscription.expired, props.businessLogin, props.role, router])

  const subscriptionStatus = useMemo(() => {
    if (business?.subscription.trialDays) {
      if (hasPaymentMethod) {
        return "Pro(Trial)"
      } else {
        return `Pro(Trial)\nExpires in ${business?.subscription.trialDays} days`
      }
    } else {
      if (business?.subscription.expired) {
        return "Expired"
      } else {
        return "Pro"
      }
    }
  }, [
    business?.subscription.expired,
    hasPaymentMethod,
    business?.subscription.trialDays,
  ])

  const showAddPaymentMethod = useMemo(
    () => props.role === MemberRole.OWNER,
    [props.role],
  )

  const handleClick = async () => {
    if (stripeCustomerId) {
      const res = await axios.post("/api/actions/stripe/portal", {
        stripeCustmerId: stripeCustomerId,
        path: router.asPath,
      })
      const url = res.data.url
      if (url) {
        window.location.href = url
      }
    }
  }

  return (
    <>
      <SideBarDrawer
        isTemporary={props.isFullPage}
        isOpen={props.isOpenDrawer}
        onClose={props.onCloseDrawer}
      >
        <Stack justifyContent={"space-between"} sx={{ minHeight: "100vh" }}>
          <Stack spacing={1} sx={{ width: 240, px: 1 }}>
            <Box
              sx={{
                pt: 1,
                pb: 1,
                px: 1,
                textAlign: "left",
              }}
            >
              <HomeButton
                onClick={() => {
                  router.push("/")
                }}
              />
            </Box>
            <Box
              sx={{
                pb: 2,
                px: 1,
                textAlign: "left",
              }}
            >
              <OrangeCapsule> {business?.name}</OrangeCapsule>
            </Box>

            {(props.role == MemberRole.ADMIN ||
              props.role == MemberRole.OWNER ||
              props.role == MemberRole.MANAGER) && (
              <List disablePadding>
                <NavigationListItem
                  isSelected={router.pathname.includes("[business]/members")}
                  onClick={onRouteFactory(`/${props.businessLogin}/members`)}
                >
                  <ListItemIcon>
                    <PeopleAltTwoTone />
                  </ListItemIcon>
                  <ListItemText primary={"Members"} />
                </NavigationListItem>
                <NavigationListItem
                  isSelected={router.pathname.endsWith(`[business]/shifts`)}
                  onClick={onRouteFactory(`/${props.businessLogin}/shifts`)}
                >
                  <ListItemIcon>
                    <CalendarTodayTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Shifts"} />
                </NavigationListItem>
                <NavigationListItem
                  isSelected={router.pathname.endsWith(
                    "[business]/attendances",
                  )}
                  onClick={onRouteFactory(
                    `/${props.businessLogin}/attendances`,
                  )}
                >
                  <ListItemIcon>
                    <HistoryTwoTone />
                  </ListItemIcon>
                  <ListItemText primary={"Attendances"} />
                </NavigationListItem>
                <NavigationListItem
                  isSelected={router.pathname.endsWith("[business]/recorder")}
                  onClick={onRouteFactory(
                    `/${props.businessLogin}/attendances/recorder`,
                  )}
                >
                  <ListItemIcon>
                    <QrCodeTwoTone />
                  </ListItemIcon>
                  <ListItemText primary={"Time Clocker"} />
                </NavigationListItem>
                <NavigationListItem
                  isSelected={router.pathname.endsWith("[business]/settings")}
                  onClick={onRouteFactory(`/${props.businessLogin}/settings`)}
                >
                  <ListItemIcon>
                    <SettingsTwoTone />
                  </ListItemIcon>
                  <ListItemText primary={"Settings"} />
                </NavigationListItem>
              </List>
            )}
            <Typography variant="h6" sx={{ mb: 2 }}>
              {"Personal"}
            </Typography>
            <List disablePadding>
              <NavigationListItem
                isSelected={router.pathname.endsWith("member/shifts")}
                onClick={onRouteFactory(
                  `/${props.businessLogin}/member/shifts`,
                )}
              >
                <ListItemIcon>
                  <CalendarTodayTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={"Shifts"} />
              </NavigationListItem>
              <NavigationListItem
                isSelected={router.pathname.endsWith("/member/shifts/requests")}
                onClick={onRouteFactory(
                  `/${props.businessLogin}/member/shifts/requests`,
                )}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary={"Shift Submit"} />
              </NavigationListItem>
              <NavigationListItem
                isSelected={router.pathname.endsWith("/member/shifts/default")}
                onClick={onRouteFactory(
                  `/${props.businessLogin}/member/shifts/default`,
                )}
              >
                <ListItemIcon>
                  <TuneIcon />
                </ListItemIcon>
                <ListItemText primary={"Default Shift"} />
              </NavigationListItem>
              <NavigationListItem
                isSelected={router.pathname.endsWith("/member/attendances")}
                onClick={onRouteFactory(
                  `/${props.businessLogin}/member/attendances`,
                )}
              >
                <ListItemIcon>
                  <HistoryTwoTone />
                </ListItemIcon>
                <ListItemText primary={"Attendances"} />
              </NavigationListItem>
              {/* <ListItemButton sx={{ borderRadius: 4 }} onClick={props.onLogout}>
            <ListItemIcon>
              <LogoutTwoTone />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItemButton> */}
            </List>
          </Stack>
          <Stack sx={{ pt: 3, pb: 2, px: 2 }}>
            <Typography
              fontWeight={"bold"}
              fontSize={16}
              whiteSpace={"pre-wrap"}
            >
              {subscriptionStatus}
            </Typography>
            {showAddPaymentMethod && (
              <AddButton onClick={handleClick} text="Add payment method" />
            )}
          </Stack>
        </Stack>
      </SideBarDrawer>
      {business != null && (
        <Modal
          open={business.subscription.expired}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            spacing={2}
            sx={{
              width: "50vw",
              background: "white",
              padding: 5,
              borderRadius: 2,
              ":focus-visible": {
                outline: "none",
              },
            }}
          >
            <Stack direction={"row"}>
              <HomeButton
                onClick={() => {
                  router.push("/")
                }}
              />
            </Stack>

            <Typography
              textAlign={"center"}
              fontWeight={"bold"}
              fontSize={16}
              whiteSpace={"pre-wrap"}
            >
              {`Currently unavailable.\nPlease set up payment method`}
            </Typography>
            <AddButton onClick={handleClick} text="Set up payment method" />
          </Stack>
        </Modal>
      )}
    </>
  )
}
