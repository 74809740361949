import { Drawer, useMediaQuery, useTheme } from "@mui/material"
import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  isOpen: boolean
  isTemporary?: boolean
  onClose(): void
}

export const SideBarDrawer: FC<Props> = (props) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const drawerWidth = 240

  const isTemporary = props.isTemporary || isMobile

  return (
    <Drawer
      variant={isTemporary ? "temporary" : "permanent"}
      anchor="left"
      open={!isTemporary || props.isOpen}
      onClose={props.onClose}
      ModalProps={{ keepMounted: true }}
      sx={{ width: drawerWidth }}
    >
      {props.children}
    </Drawer>
  )
}
