import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Button, Menu, MenuItem, Stack } from "@mui/material"
import { useState, type FC } from "react"

type BusinessItem = {
  id: string
  name: string
  login: string
}
type Props = {
  current: string
  businessItems: BusinessItem[]
  onSelected(businessLogin: string): void
}

export const BusinessSelector: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const currentbusinessName = props.businessItems.find(
    (businessItem) => businessItem.login === props.current,
  )?.name
  return (
    <Stack>
      <Button
        variant="text"
        color="primary"
        style={{ borderRadius: "16px", textTransform: "none" }}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        {currentbusinessName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        {props.businessItems.map((businessItem) => (
          <MenuItem
            key={businessItem.id}
            onClick={() => props.onSelected(businessItem.login)}
            style={{ fontSize: "16px" }}
          >
            {businessItem.name}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  )
}
