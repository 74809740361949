import { ApolloError } from "@apollo/client"
import Error from "next/error"
import type { FC } from "react"
import { BusinessLogin } from "app/[business]/components/BusinessLogin"

type Props = {
  error: unknown
  resetError(): void
}

const BusinessError: FC<Props> = (props) => {
  if (props.error instanceof ApolloError) {
    for (const error of props.error.graphQLErrors) {
      if (error.extensions.code === "UNAUTHORIZED") {
        return <BusinessLogin resetError={props.resetError} />
      }
    }
  }

  return <Error statusCode={500} title={"ERROR"} />
}

export default BusinessError