import MenuTwoTone from "@mui/icons-material/MenuTwoTone"
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useRouter } from "next/router"
import { type FC } from "react"
import { BusinessSelector } from "app/[business]/components/BusinessSelector"
import { AccountButton } from "app/components/AccountButton"
import {
  useViewerBusinessesQuery,
  useViewerUserQuery,
} from "interface/__generated__/react"

type Props = {
  title: string
  isFullPage: boolean
  onOpenDrawer(): void
}

export const BusinessAppBar: FC<Props> = (props) => {
  const router = useRouter()

  const businessLogin = router.query.business as string

  const { data, error: viewerUserError } = useViewerUserQuery()

  if (viewerUserError) {
    throw viewerUserError
  }

  const user = data?.viewer?.user ?? null

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const hasDrawerButton = isMobile || props.isFullPage

  const onRouteAccount = () => {
    router.push(`/${businessLogin}/account`)
  }

  const { data: viewerBusiness, error: viewerBusinessesError } =
    useViewerBusinessesQuery({
      variables: { withDetails: false },
    })
  if (viewerBusinessesError) {
    throw viewerBusinessesError
  }

  const businesses = viewerBusiness?.viewer?.businesses ?? []
  const businessItems = businesses.map((business) => ({
    id: business.id,
    login: business.login,
    name: business.name,
  }))

  return (
    <AppBar position="sticky" color="default" sx={{ backgroundColor: "#fff" }}>
      <Toolbar sx={{ pr: "1rem !important" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {hasDrawerButton && (
              <Box>
                <IconButton onClick={props.onOpenDrawer}>
                  <MenuTwoTone />
                </IconButton>
              </Box>
            )}
            <Typography sx={{ fontWeight: "bold" }}>{props.title}</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <BusinessSelector
              current={businessLogin}
              businessItems={businessItems}
              onSelected={(businessLogin) => {
                router.push(`/${businessLogin}`)
              }}
            />
            {user !== null && (
              <AccountButton
                alt={user.name}
                fileId={user.avatarFileId}
                onClick={onRouteAccount}
              />
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
