import { Button, Link, Stack, Typography } from "@mui/material"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { useSnackbar } from "notistack"
import type { FC } from "react"
import { useState } from "react"
import { HomeAppBar } from "app/components/HomeAppBar"
import { HomeLoginForm } from "app/components/HomeLoginForm"
import SSPaper from "app/components/SSPaper"
import { useLoginWithTokenMutation } from "interface/__generated__/react"
import type { OnLoginWithEmail } from "interface/types/onLoginWithEmail"

type Props = {
  resetError(): void
}

export const BusinessLogin: FC<Props> = (props) => {
  const [loginWithToken] = useLoginWithTokenMutation()

  const [isLoading, setLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const onLogin: OnLoginWithEmail = async (form) => {
    try {
      const result = await signInWithEmailAndPassword(
        getAuth(),
        form.email,
        form.password,
      )
      const token = await result.user.getIdToken()
      await loginWithToken({
        variables: {
          input: {
            token,
            password: form.password,
          },
        },
      })
      props.resetError()
    } catch (error) {
      setLoading(false)
      enqueueSnackbar("Failed to login", { variant: "error" })
    }
  }

  return (
    <Stack>
      <HomeAppBar>
        <Link href="/register">
          <Button>{"Sign up"}</Button>
        </Link>
      </HomeAppBar>
      <Stack sx={{ px: 4, pt: 8 }} direction={"row"} justifyContent={"center"}>
        <Stack spacing={2} sx={{ width: "100%", maxWidth: 360 }}>
          <SSPaper sx={{ borderRadius: 3 }}>
            <Stack
              spacing={4}
              sx={{
                px: 4,
                pt: 4,
                pb: 5,
                width: "100%",
              }}
            >
              <Stack direction={"row"} justifyContent={"center"}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                  {"Business Login"}
                </Typography>
              </Stack>
              <HomeLoginForm isLoading={isLoading} onLogin={onLogin} />
            </Stack>
          </SSPaper>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {"© 2023 ShiftSmart"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
