import { ErrorBoundary } from "@sentry/react"
import { useRouter } from "next/router"
import type { FC, ReactNode } from "react"
import { Suspense, useCallback, useState } from "react"
import { BusinessAppBar } from "app/[business]/components/BusinessAppBar"
import { BusinessDrawer } from "app/[business]/components/BusinessDrawer"
import { BusinessLoading } from "app/[business]/loading"
import { MainLayoutBox } from "app/components/MainLayoutBox"
import { useViewerMemberQuery } from "interface/__generated__/react"
import BusinessError from "pages/[business]/error"

type Props = {
  children: ReactNode
  title: string
  isFullPage?: boolean
}

const BusinessLayout: FC<Props> = (props) => {
  const router = useRouter()

  const businessLogin = router.query.business as string

  const { data: memberData, error: viewerMemberError } = useViewerMemberQuery({
    variables: {
      businessLogin: businessLogin,
    },
  })

  if (viewerMemberError) {
    throw viewerMemberError
  }

  const member = memberData?.viewer?.member ?? null

  const [isOpenDrawer, openDrawer] = useState(false)

  const onOpenDrawer = useCallback(() => {
    openDrawer(true)
  }, [])

  const onCloseDrawer = useCallback(() => {
    openDrawer(false)
  }, [])

  if (member === null) {
    return null
  }

  return (
    <Suspense fallback={<BusinessLoading />}>
      <ErrorBoundary fallback={(props) => <BusinessError {...props} />}>
        <BusinessDrawer
          role={member.role}
          businessLogin={businessLogin}
          isFullPage={props.isFullPage ?? false}
          isOpenDrawer={isOpenDrawer}
          onCloseDrawer={onCloseDrawer}
        />

        <MainLayoutBox isFullPage={props.isFullPage ?? false}>
          <BusinessAppBar
            title={props.title}
            isFullPage={props.isFullPage ?? false}
            onOpenDrawer={onOpenDrawer}
          />
          {props.children}
        </MainLayoutBox>
      </ErrorBoundary>
    </Suspense>
  )
}

export default BusinessLayout
