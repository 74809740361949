import { ListItemButton } from "@mui/material"
import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  isSelected?: boolean
  onClick(): void
}

export const NavigationListItem: FC<Props> = (props) => {
  return (
    <ListItemButton
      sx={{ borderRadius: 4, mb: 1 }}
      selected={props.isSelected}
      onClick={props.onClick}
    >
      {props.children}
    </ListItemButton>
  )
}
