import { Stack } from "@mui/material"
import type { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  isFullPage?: boolean
  hasAppBar?: boolean
}

/**
 * @param props
 * @returns
 */
export const MainLayoutBox: FC<Props> = (props) => {
  const drawerWidth = 240

  return (
    <Stack
      sx={(theme) => {
        if (props.isFullPage) {
          return { pb: 2 }
        }
        return {
          [theme.breakpoints.up("md")]: {
            pl: `${drawerWidth}px`,
          },
          pb: 2,
        }
      }}
    >
      {props.children}
    </Stack>
  )
}
