import { Chip } from "@mui/material"
import { styled } from "@mui/system"
import React from "react"

interface OrangeCapsuleProps {
  children: React.ReactNode
}

const OrangeChip = styled(Chip)({
  backgroundColor: "orange",
  color: "white",
  borderRadius: "16px",
  fontSize: "14px",
  fontWeight: 700,
})

const OrangeCapsule: React.FC<OrangeCapsuleProps> = ({ children }) => {
  return <OrangeChip label={children} />
}

export default OrangeCapsule
